/*** IMPORTS FROM imports-loader ***/
var define = false;

import Swiper from "swiper/js/swiper.min";

export default {
    init: (app, CookieManager, Swiper, customGoogleMap) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body'),
            $cookieBanner = $('.cookie-banner'),
            $cookieClose = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse')
            ;


        /*
        |
        | Cookie Manager
        |-----------------
        */
        new CookieManager($cookieBanner, {
            name: 'adveris_cookie_use',
            duration: 30,
            buttons: {
                accept: $cookieAccept,
                refuse: $cookieRefuse,
                close: $cookieClose
            },
            onAccept: () => {
                console.log('accpeted')
            },
            onRefuse: () => {
                console.log('refused')
            }
        });

        $body.on('loaderEnd', () => console.log('ended'))


        /* slide link */
        jQuery('a.animlink').click(function (event) {
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 50
            }, 800);
            event.preventDefault();
        });

        /* slider home */
        var swiper = new Swiper('#homeswiper.swiper-container', {
            slidesPerView: 1,
            effect: 'fade',
            loop: true,
            speed: 1000,
            autoplay: false,
            fadeEffect: {
                crossFade: true
            },
            loop: true,
            grabCursor: true,
            centeredSlides: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            navigation: {
                prevEl: '.swiper-btn-prev',
                nextEl: '.swiper-btn-next'
            }
        });

        /* slider sticky */
        var swiper = new Swiper('#sticky-slider.swiper-container', {
            slidesPerView: 1,
            effect: 'fade',
            loop: true,
            speed: 2000,
            autoplay: true,
            fadeEffect: {
                crossFade: true
            },
            loop: true,
            grabCursor: true,
            centeredSlides: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            navigation: {
                prevEl: '.swiper-btn-prev',
                nextEl: '.swiper-btn-next'
            }
        });

        /* Replace intro by stycky slider after 5 sec */



        /* slider histoire */
        var swiper = new Swiper('#clients-slider.swiper-container', {
            slidesPerView: 1,
            loop: true,
            speed: 1000,
            autoplay:
            {
                delay: 3000
            },
            grabCursor: true,
            centeredSlides: true,
            // pagination: {
            //     el: '.swiper-cli-pagination',
            //     clickable: true
            // },
            navigation: {
                prevEl: '.swiper-cli-btn-prev',
                nextEl: '.swiper-cli-btn-next'
            },
            breakpoints: {
                992: {
                    slidesPerView: 3,
                },
                767: {
                    slidesPerView: 1,
                },
                480: {
                    slidesPerView: 1,
                }
            }
        });


        /* Filter */
        jQuery(function () {
            var selectedClass = "";
            $(".fil-cat").click(function () {

                $(".fil-cat").removeClass('active');
                $(this).addClass('active');
                selectedClass = $(this).attr("data-rel");

                $("#section-clients").fadeTo(100, 0.1);
                $("#section-clients .tile").not("." + selectedClass).fadeOut().removeClass('scale-anm');
                setTimeout(function () {
                    $("." + selectedClass).fadeIn().addClass('scale-anm');
                    $("#section-clients").fadeTo(300, 1);
                }, 300);
            });
        });

        /* Popup */
        const $modalv = jQuery("#modal-video");
        if ($modalv.length) {
            jQuery("#modal-video").iziModal({
                // options here
            });
            jQuery(document).on('click', '.trigger-link', function (event) {
                event.preventDefault();
                jQuery('#modal-video').iziModal('open');
            });
        }

        /* Chiffres animates */
        const $chiffres = jQuery(".histoire_chiffres");
        if ($chiffres.length) {
            var a = 0;
            $(window).scroll(function () {
                var oTop = $('.histoire_chiffres').offset().top - window.innerHeight;
                if (a == 0 && $(window).scrollTop() > oTop) {
                    $('.counter-value').each(function () {
                        var $this = $(this),
                            countTo = $this.attr('data-count');
                        $({
                            countNum: $this.text()
                        }).animate({
                            countNum: countTo
                        },
                            {
                                duration: 3500,
                                easing: 'swing',
                                step: function () {
                                    $this.text(Math.floor(this.countNum));
                                },
                                complete: function () {
                                    $this.text(this.countNum);
                                    //alert('finished');
                                }
                            });
                    });
                    a = 1;
                }
            });
        }


        jQuery('.btn-menu').click(function (event) {
            jQuery('header').toggleClass('bg-blue-menu');
        });

        jQuery('.more-team').click(function (event) {
            jQuery('.itemcard').toggleClass('all');

            if ($(".itemcard").hasClass("all")) {
                $(this).text("Voir moins");
            } else {
                $(this).text("Voir plus");
            }

            $([ document.documentElement, document.body ]).animate({
                scrollTop: $("#section4").offset().top
            }, 20);

        });

        /* menu sticky */
        jQuery(document).ready(function () {
            jQuery(window).scroll(function () {
                var windowpos = jQuery(window).scrollTop();

                if (windowpos >= 10) {
                    jQuery("header").addClass("sticky");
                } else {
                    jQuery("header").removeClass("sticky");
                }
            });
        });

        /* menu home sticky */
        jQuery(document).ready(function () {
            jQuery(window).scroll(function () {
                var windowpos = jQuery(window).scrollTop();

                if (windowpos >= 10) {
                    jQuery("header").addClass("sticky");
                } else {
                    jQuery("header").removeClass("sticky");
                }
            });
        });

        /* scroll to top */
        jQuery(document).ready(function ($) {
            $(window).scroll(function () {
                if ($(this).scrollTop() > 200) {
                    $('.scrollToTop').fadeIn();
                } else {
                    $('.scrollToTop').fadeOut();
                }
            });
            $('.scrollToTop').click(function () {
                $('html, body').animate({ scrollTop: 0 }, 800);
                return false;
            });
        });

        /* Player plyr nous rejoindre */
        jQuery(document).ready(function ($) {

            plyr.setup(document.querySelectorAll('#js-player'));

            $('#js-player').on('ready', function (event) {
                $(this).addClass('plyr--init-hide-controls');
            }).on('play', function (event) {
                $(this).removeClass('plyr--init-hide-controls');
            });
        });


    }
}
